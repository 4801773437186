.teddy-input {
    border: none;
    border-bottom: 1px var(--text-color) solid;
    background-color: var(--background-color);   
    width: 70%;
    font-size: 20px;
    padding: 5px;
    color: var(--text-color);
    border-radius: 0px;
    transition: background-color 0.4s
    /* transition: border-width 0.2s linear; */
}

.wrong {
    border-bottom: 1px rgb(200, 46, 46) solid;
    /* transition: border-width 0.2s linear; */
}

.teddy-input:focus {
    outline: none;
    border-width: 2px;
}

.teddy-submit-input {
    transition: transform 0.4s;
}

.teddy-submit-input:hover {
    transform: scale(1.3);
}

.incorrect-text {
    color: rgb(200, 46, 46);
    font-family: "InterRegular";
    padding: 15px;
}

.incorrect-text-hidden {
    color: rgb(200, 46, 46);
    font-family: "InterRegular";
    opacity: 0;
    padding: 15px;
}