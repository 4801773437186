.i-project-title {
    font-size: 30px;
    font-family: "InterBold";
    padding-bottom: 10px;
    color: var(--text-color);
    text-align: center;
}

.i-project-subtitle {
    font-family: "InterRegular";
    font-size: 18px;
    font-style: italic;
    margin-bottom: 25px;
    color: var(--text-color);
    text-align: center;

    /* background-color: rgb(213, 223, 231); */
    /* color: #3AACFF; */
    /* padding: 5px; */
    /* border-radius: 3px; */
}

.blurb {
    /* or should i do 18px? */
    font-size: 18px; 
    font-family: "InterRegular";
    text-indent: 1em;
    line-height: 1.6;
    color: var(--text-color);
    display: block;

}

.info-title {
    background-color: var(--info-tag-bg);
    color: var(--info-tag-color);
    font-family: "InterBold";
    padding: 5px;
    font-size: 12px;
    border-radius: 3px;
}

.info-content {
    font-family: "InterRegular";
    font-size: 16px;
    color: var(--text-color);

}

.info-content-link {
    font-family: "InterRegular";
    font-size: 16px;
    color: var(--link);
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 5px;
}

.info-content-link-disabled {
    font-family: "InterRegular";
    font-size: 16px;
    color: var(--link);
    opacity: 0.5;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 5px;

}

.info-content-link:hover {
    text-decoration: underline;
    border-bottom: solid 1px
}

.awards-header {
    font-size: 25px;
    font-family: "InterBold";
    padding-bottom: 5px;
    padding-top: 15px;
    color: var(--text-color);

}

iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 10px;
}

.github-btn {
    display: flex;
    gap: 5px;
    font-size: 15px;
    color: white;
    background-color: #2b9278;
    justify-content: center;
    align-items: center;
    font-family: "InterMedium";
    border-radius: 10px;
    padding: 5px;
    transition: background-color 0.2s linear, transform 0.2s ease-in-out;
}

.github-btn:hover {
    background-color: #1d6352;
    transform: rotate(15deg) scale(1.2);
}

.designation {
    width: 2.5em;
    border-radius: 10px;
    padding: 5px;
    transition: transform 0.2s ease-in-out, background-color 0.2s linear;
}

.designation:hover {
    /* background-color: rgb(231, 231, 231); */
    transform: rotate(15deg) scale(1.2);
}

.tooltip {
    opacity: 0;
    background-color: var(--tooltip-bg);
    color: var(--text-color);
    border-radius: 6px;
    padding: 5px;
    font-family: "InterRegular";
    z-index: 1;
    position: absolute;
    top: -2.3rem;
    text-align: center;

    transition: opacity 0.2s linear;
}

.designation:hover+.tooltip {
    opacity: 1;
}


.designation-container-mobile {
    display: none;
}

.designation-mobile {
    display: none;
}

.github-container-mobile {
    display: none;

}

.github-icon-mobile {
    display: none;
}



@media only screen and (max-width: 600px) {
    .github-btn {
        display: none;

    }

    .designation {
        display: none;
    }

    .designation-container-mobile {
        background-color: var(--tooltip-bg);
        color: var(--text-color);
        border-radius: 6px;
        padding: 5px 10px;
        font-family: "InterRegular";
        z-index: 1;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .designation-mobile {
        width: 2em;
        border-radius: 10px;
        padding: 5px;
        transition: transform 0.2s ease-in-out, background-color 0.2s linear;
        display: inline;

    }

    .github-container-mobile {
        /* margin-top: 1em; */
        display: flex;
        gap: 10px;
        color: white;
        background-color: #2b9278;
        align-items: center;
        font-family: "InterMedium";
        border-radius: 6px;
        padding: 5px 10px;
        transition: background-color 0.2s linear;
    }

    .github-icon-mobile {
        width: 2em;
        display: inline;
    }

    .github-container-mobile:hover {
        background-color: #1d6352;
    }
}