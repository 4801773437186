.navbar-text {
    font-family: "InterRegular";
    font-size: 20px;
    text-decoration: none;
    color: var(--navbar-text);
    transition: all 0.2s linear;
}

#navbar a.active {
    color: var(--navbar-text-active);
}

#navbar {
    /* background-color: var(--navbar-bg); */
    background: transparent;
    backdrop-filter: blur(30px);
    display: flex;
    gap: 50;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 100;
    padding-bottom: 20px;
    transition: background-color 0.4s linear;
}

#navbar a:hover {
    color: var(--navbar-text-active);
}

.home-super-container {
    min-height: 80vh; 
    display: flex;
    justify-content: center; 
    align-items: center; 
    /* margin-top: -75px;  */
}

.home-container {
    width: clamp(1rem, 80vw, 80rem);
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 20px 0;
    /* background-color: red; */
}

.myface {
    width: clamp(200px, 30vw, 400px);
    height: clamp(200px, 30vw, 400px);
}

.darkButton {
    background-color: purple;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: background-color 0.4s;
}

.samButton {
    /* background-color: var(--text-color); */
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: transform 0.2s ease-in-out;
}



#ee:hover > #ff {
    transform: rotate(30deg);
}

.darkButton:hover {
    background-color: rgb(85, 14, 85);
}

.lightButton {
    background-color: beige;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: background-color 0.4s;
}

.lightButton:hover {
    background-color: rgb(200, 200, 178);
}

.title {
    font-family: "InterBold";
    background: linear-gradient(to right, var(--gradient-start) 0%, var(--gradient-end) 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: var(--text-color);
    font-size: clamp(30px, 7vw, 75px);
    line-height: 1em;
}

.subtitle {
    font-family: "InterRegular";
    /* font-size: 45px; */
    font-size: clamp(20px, 3.5vw, 45px);
    color: var(--text-color);
    margin: 10px 0;
}

.social-icon:hover {
    background-color: var(--icon-hover);
}

.social-icon {
    padding: 10px;
    border-radius: 15px;
    transition: background-color 0.2s linear;
}

.social-container {
    display: flex;
    gap: 45px;
    margin-left: -10px
}

.home-info-container {
    justify-content: center;
    gap: 15px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.home-container-small {
    display: none;
}

#navbar-small {
    display: none;
}

.view-projects-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: var(--gradient-end);
    color: var(--background-color);
    padding: 10px;
    border-radius: 10px;
    font-family: "InterMedium";
    font-size: clamp(20px, 2vw, 26px);
    transition: background-color 0.4s;
    text-decoration: none;
}

.view-projects-btn:hover {
    background-color: var(--view-projects-btn-hover);
}

.navbar-title {
    font-family: "InterMedium";
    font-size: 24px;
    text-decoration: none;
    color: var(--navbar-text-active);
    transition: all 0.2s linear;
}


@media only screen and (max-width: 814px) {
    .title {
        font-family: "InterBold";
        background: linear-gradient(to right, var(--gradient-start) 0%, var(--gradient-end) 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: var(--text-color);
        font-size: clamp(30px, 10vw, 75px);
    }

    .subtitle {
        font-family: "InterRegular";
        font-size: clamp(20px, 4vw, 45px);
        color: var(--text-color);
        text-align: center;
    }

    .home-container {
        display: none;
    }

    .home-info-container {
        align-items: center;
    }

    .home-container-small {
        width: clamp(1rem, 80vw, 80rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        text-align: center;
    }

    .home-super-container {
        min-height: 79vh;  
        justify-content: center;
        align-items: center;
        display: flex;
        
    }

    .social-container {
        gap: 35px;
    }

    .view-projects-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: var(--gradient-end);
        color: var(--background-color);
        padding: 10px;
        border-radius: 10px;
        font-family: "InterRegular";
        font-size: clamp(15px, 2.5vw, 45px);
        transition: background-color 0.4s;
        text-decoration: none;
    }
}

/* Change navbar to compressed */
@media only screen and (max-width: 600px) {
    #navbar {
        display: none;
    }

    #navbar-small {
        background: transparent;
        backdrop-filter: blur(30px);
        display: flex;
        gap: 50px;
        justify-content: center;
        align-items: center;
        padding: 15px 0;
        width: 100vw;
        position: fixed;
        top: 0;
        z-index: 100;
    }

    .nav-menu-btn {
        border: solid 1px var(--navbar-text);
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        /* transition: background-color 0.2s; */
    }

    .nav-menu-btn-open {
        background-color: var(--navbar-menu-btn-open);
        border: solid 1px var(--navbar-text);
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        /* transition: background-color 0.2s; */
    }
    
    .menu {
        position: absolute;
        margin: 5px 0;
        padding: 0;
        top: 60px;
        right: 5vw;
        border: 1px solid grey;
        width: 200px;
        border-radius: 10px;
        overflow: hidden;
        z-index: 100;
    }

    .menu-item {
        margin: 0;
        transition: background-color 0.4s;
        text-decoration: none;
    }

    .menu-btn {
        width: 100%;
        height: 100%;
        text-align: left;
        color: inherit;
        border: none;
        padding: 10px;
        margin: 0;
        font: inherit;
        background-color: var(--background-color);
        color: var(--text-color);
        
    }

    .menu-btn:hover {
        background-color: var(--navbar-menu-hover);
    }


}