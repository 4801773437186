@font-face {
  font-family: "InterBold";
  src: local("InterBold"),
  url("./fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: bold
}

@font-face {
  font-family: "InterRegular";
  src: local("InterRegular"),
  url("./fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: normal
}

@font-face {
  font-family: "InterMedium";
  src: local("InterMedium"),
  url("./fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500
}

[data-theme="light"] {
  --background-color: #f6f6f6;
  --text-color: black;
  --navbar-bg: rgba(246, 246, 246, 0.7);
  --navbar-text: #9B9B9B;
  --navbar-text-active: black;
  --navbar-menu-hover: #d2d2d2;
  --background-highlight: rgb(231, 231, 231);
  --project-tag-bg: rgb(213, 223, 231);
  --project-tag-color: #3AACFF;
  --info-tag-bg: rgb(217, 231, 213);
  --info-tag-color: #26a944;
  --info-content-link: #2b9278;
  --github-color: white;
  --github-button: #2b9278;
  --github-button-hover: #1d6352;
  --gradient-start: #3674A0;
  --gradient-end: #2b9278;
  --penn-red: #990000;
  --link: #2b9278;
  --icon-hover: rgb(231, 231, 231);
  --tooltip-bg: rgb(231, 231, 231);
  --navbar-menu-btn-open: rgba(109, 109, 109, 0.2);
  --view-projects-btn-hover: #247964;
  --light-text-color: rgb(128, 128, 128);
}

[data-theme="dark"] {
  --background-color: rgb(32, 32, 35);
  --text-color: rgba(255, 255, 255, 0.82);
  --navbar-bg: rgba(26, 26, 26, 0.2);
  --navbar-text: #9B9B9B;
  --navbar-text-active: white;
  --navbar-menu-hover: rgb(71, 71, 77);
  --background-highlight: rgb(231, 231, 231);
  --project-tag-bg: rgba(69, 94, 113, 0.423);
  --project-tag-color: #99d0fa;
  --info-tag-bg: rgba(115, 231, 80, 0.12);
  --info-tag-color: #4dcc6b;
  --info-content-link: #2b9278;
  --github-color: white;
  --github-button: #2b9278;
  --github-button-hover: #1d6352;
  --gradient-start: #67a3c9;
  --gradient-end: #66d7b8;
  --penn-red: #cf2222;
  --link: #2b9278;
  --icon-hover: rgb(59, 59, 59);
  --tooltip-bg: rgb(83, 83, 83);
  --navbar-menu-btn-open: rgba(171, 171, 171, 0.2);
  --view-projects-btn-hover: #52ad95;
  --light-text-color: rgba(146, 146, 146, 0.82);
}

[data-theme="dark"] img {
  filter: brightness(0.8);
  transition: filter 0.4s, transform .4s background-color 0.2s linear;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}
