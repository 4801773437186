.category {
    gap: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}

.category-title {
    font-family: "InterBold";
    font-size: 30px;
	background: linear-gradient(to right, var(--gradient-start) 0%, var(--gradient-end) 100%);
	background-clip: text;
	-webkit-text-fill-color: transparent;
    text-align: center;
    
}

.category-subtitle {
    font-family: "InterRegular";
    font-size: 20px;
    text-align: center;
}


.row-container {
    width: clamp(1rem, 90vw, 60rem)
}

.category-container {
    /* border: solid 3px #E4E4E4; */
    border-radius: 15px;
    padding: 30px;
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items:flex-start
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    row-gap: 50px; */
}

.project-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 40%; */
    gap: 10px;
    width: 33%;
}

.project-image {
    object-fit: cover; 
    object-position: center; 
    width: 100%;
    height: 200px;
    border-radius: 15px;
    transition: transform .4s; 
}

.project-image:hover {
    transform: scale(1.05)
}

.project-title {
    padding-top: 10px;
    font-size: 20px;
    font-family: "InterMedium";
    color: var(--text-color);
}

.project-subtitle {
    font-size: 18px;
    font-family: "InterRegular";
    text-align: center;
    color: var(--text-color);
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;

}

.project-tag {
    background-color: var(--project-tag-bg);
    color: var(--project-tag-color);
    font-family: "InterBold";
    padding: 5px;
    font-size: 12px;
    border-radius: 3px;
}

.entire-container {
    display: flex;
    align-items: center;
    padding-top: 5.6vh;
    flex-direction: column;
    padding-bottom: 5vh; 
}

.entire-projects-container {
    display: flex;
    align-items: center;
    padding-top: 5vh;
    flex-direction: column;
    padding-bottom: 5vh; 
}

@media only screen and (max-width: 818px) {
    .category {
        gap: 0px;
        display: flex;
        flex-direction: column;
        /* padding-bottom: 10px; */
    }
    
    .category-container {
        /* border: solid 3px #E4E4E4; */
        border-radius: 15px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 50px;
        align-items: center;
        /* display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        row-gap: 50px; */
    }
    
    .project-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: clamp(1rem, 85vw, 25rem);
    }
    
    .project-image {
        object-fit: cover; 
        object-position: center; 
        width: 100%;
        height: 200px;
        border-radius: 15px;
        transition: transform .4s; 
    }

}

@media only screen and (max-width: 600px) {
    .entire-container {
        display: flex;
        align-items: center;
        padding-top: 1rem;
        flex-direction: column;
        padding-bottom: 5vh; 
    }

    .entire-projects-container {
        display: flex;
        align-items: center;
        padding-top: 1rem;
        flex-direction: column;
        padding-bottom: 5vh; 
    }
}